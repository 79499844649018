import React from "react"

const NotFoundPage = () => (

	<div className="page 404 ColorKey" data-bgcolor="#ffffff">
    <div className="main-content">
      <div className="main-wrap">
        <h1>404 – NOT FOUND</h1>
    		<p>You just hit a route that doesn&#39;t exist... the sadness.</p>
      </div>
    </div>
  </div>
)

export default NotFoundPage
